
import { useEffect, useState } from 'react';

// img
import isotipo from './Images/Isotipo.png';

// components
import GestionarCitas from './Pages/GestionarCitas/GestionarCitas';
import StepBtn from './Components/StepBtn/StepBtn';
import LogIn from './Pages/LogIn/LogIn';


// Prime react
import { locale, addLocale } from 'primereact/api';

// styles
import "./Styles/AppLayout.scss"



function App() {
  // Form functionality states
  const [stepCounter, setStepCounter] = useState(0)
  const [ableNextStep, setAbleNextStep] = useState(false)

  // Login Data
  const [asesorData, setAsesorData] = useState({
    asesorCua: "",
    asesorName: "",
    asesorPhone: "",
    asesorEmail: ""
  })
  const [cua, setCua] = useState("2543")
  const [asesorName, setAsesorName] = useState("Cesar Hernández Ramìrez")
  const [asesorPhone, setAsesorPhone] = useState("3327049431")
  const [asesorEmail, setAsesorEmail] = useState("cesar@gmail.com")




  // Props packs
  const StepsProps = {
    stepCounter, setStepCounter, 
    ableNextStep, setAbleNextStep
  }
  const AsesorProps = {asesorData, setAsesorData, cua, asesorName, asesorPhone, asesorEmail, setCua, setAsesorName, setAsesorPhone, setAsesorEmail}






  // PRIME REACT español
  useEffect
  (() => {
    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Limpiar',
      //...
    });    
    locale('es');
  }, [])  









  return (
    <div className="appLayout">

      <div className="bg-overlay"/>
      <header className="Header" onClick={() => window.location.href = "."}><img src={isotipo}/></header>
      

      <main className="App">

        <StepBtn disabled={true} {...StepsProps} direction="left" />











        {/* 0 is for the Login Form */}
        {/* 1 is for waiting a response to the auth api */}
        {stepCounter < 2 && <LogIn {...AsesorProps} {...StepsProps} />}

        {/* 2 es para el Form del Nuevo Asesor */}
        {stepCounter >= 2 && <GestionarCitas title={"Gestionar mis registros"} {...AsesorProps} />}












        {/* cuando llego al formulario del nuevo asesor oculto este botón porque allá uso el de enviar, no este (hice otro en <NuevaCita />) */}
        {
          stepCounter < 2 &&
          <StepBtn disabled={!ableNextStep} {...StepsProps} direction="right" />
        }

        <div className="mobile-actions">
          <StepBtn disabled={true} {...StepsProps} direction="left" />
          <StepBtn disabled={!ableNextStep} {...StepsProps} direction="right" />
        </div>

      </main>


    </div>
  );
}

export default App;