import React, { useEffect, useState } from "react";


//import from prime react
import InputCalendar from "../InputCalendar/InputCalendar";


//Dropdown options
const EdadClienteOptions = [
  { key: 0, name: "Menor de 25" },
  { key: 1, name: "25 a 30" },
  { key: 2, name: "30 a 40" },
  { key: 3, name: "40 a 50" },
  { key: 4, name: "50 a 60" },
  { key: 5, name: "Mayor de 60" },
];

const ProductOptions = [
  { key: 1, name: "Vida" },
  { key: 2, name: "GMM" },
  { key: 3, name: "Auto" },
  { key: 4, name: "Patrimoniales" },
];
const PorcentageOptions = [
  {key: 0, name: "0%"},
  {key: 1, name: "25%"},
  {key: 2, name: "50%"},
  {key: 3, name: "75%"},
  {key: 4, name: "100%"},
]
const VincularConOptions = {
  "Vida": [
    {key: 1, name: "Visión Plus"},
    {key: 2, name: "Privilegio Universal"},
    {key: 3, name: "Trasciende"},
    {key: 4, name: "Ordinario de Vida"},
    {key: 5, name: "Platino Universal"},
    {key: 6, name: "Consolida"},
    {key: 7, name: "Proyecta"},
    {key: 8, name: "Proyecta Afecto"},
    {key: 9, name: "Consolida Total"},
    {key: 10, name: "Elige"},
    {key: 11, name: "GNP Vida a tus sueños"},
    {key: 12, name: "Dotal"},
    {key: 13, name: "Capitaliza"},
    {key: 14, name: "Vida Inversión"},
    {key: 15, name: "GNP Profesional Abuelos"},
    {key: 16, name: "Profesional"},
    {key: 17, name: "Platino"},
    {key: 18, name: "Privilegio GNP"},
  ],
  
  "GMM": [
    {key: 1, name: "Personaliza"},
    {key: 2, name: "Premium"},
    {key: 3, name: "Platino"},
    {key: 4, name: "Flexibles"},
    {key: 5, name: "Versátil"},
    {key: 6, name: "Conexión GNP"},
    {key: 7, name: "Conexión Línea Azul"},
    {key: 8, name: "GNP Indemniza"},
    {key: 9, name: "Acceso"},
    {key: 10, name: "Esencial"},
    {key: 11, name: "Plenitud"},
    {key: 12, name: "VIP"},
    {key: 13, name: "Internacional"},
    {key: 14, name: "GNP Enlace Internacional"},
    {key: 15, name: "Vínculo Mundial"},
    {key: 16, name: "Alta Especialidad"},
  ],

  "Auto": [
    {key: 1, name: "Automóviles Individual"},
    {key: 2, name: "Motos"},
  ],
  
  "Patrimoniales": [
    {key: 1, name: "GNP Riesgos Naturales"},
    {key: 2, name: "Mi mascota GNP"},
    {key: 3, name: "Hogar Versátil"}
  ],
}



export default function ({ row, index, handleChangeRow }) {
  const [initRender, setInitRender] = useState(1)


  const [editingComentarios, setEditingComentarios] = useState(false);
  const [comentariosValue, setComentariosValue] = useState(row.comentarios);


  //campos editables
  //FECHA
  const [editingPrimeraFecha, setEditingPrimeraFecha] = useState(false);
  const [PrimeraFechaValue, setPrimeraFechaValue] = useState(row?.fecha ? stringToFecha(row.fecha) : '');
  
  //NOMBRE CLIENTE
  const [editingNombreCliente, setEditingNombreCliente] = useState(false);
  const [NombreClienteValue, setNombreClienteValue] = useState(row.nombre_cliente);
  
  //TELEFONO
  const [editingTelefono, setEditingTelefono] = useState(false);
  const [TelefonoValue, setTelefonoValue] = useState(row.telefono_cliente);
  
  //CORREO
  const [editingCorreoCliente, setEditingCorreoCliente] = useState(false);
  const [CorreoClienteValue, setCorreoClienteValue] = useState(row.correo_cliente);
  
  //EDAD CLIENTE
  const [EdadCliente, setEdadCliente] = useState(EdadClienteOptions.find(option => option.name === row.edad_cliente)?.name || "")
  
  //FECHA
  const [editingFechaNacimiento, setEditingFechaNacimiento] = useState(false);
  const [FechaNacimientoValue, setFechaNacimientoValue] = useState(row?.fecha_nacimiento ? stringToFecha(row.fecha_nacimiento) : '');

  //PRODUCTO
  const [producto, setProducto] = useState(ProductOptions.find(option => option.name === row.producto)?.name || "")

  //VINCULAR CON 
  const [vincularCon, setVincularCon] = useState(row?.vincular_con ?? "")

  //VINCULAR CON 
  const [porcentajeCierre, setPorcentajeCierre] = useState(PorcentageOptions.find(option => option.name === row.porcentaje_de_cierre)?.name || "")
  

  //FUNCIONES PARA EDITAR CAMPOS
  //FECHA PRIMERA CITA
  const handlePrimeraFechaChange = (e) => {
    setPrimeraFechaValue(e.value)
  };


  //NOMBRE CLIENTE
  const handleNombreClienteChange = (e) => {
    setNombreClienteValue(e.target.value);
  };
  const handleNombreClienteBlur = () => {
    setEditingNombreCliente(false);
    handleChangeRow(row.uuid, 'nombre_cliente', NombreClienteValue);
  };
  
  //TELEFONO
  const handleTelefonoChange = (e) => {
    setTelefonoValue((v) => e.target.validity.valid ? e.target.value : v);
  };
  const handleTelefonoBlur = () => {
    setEditingTelefono(false);
    handleChangeRow(row.uuid, 'telefono_cliente', TelefonoValue);
  };
  
  //CORREO 
  const handleCorreoClienteChange = (e) => {
    setCorreoClienteValue(e.target.value);
  };
  const handleCorreoClienteBlur = () => {
    setEditingCorreoCliente(false);
    handleChangeRow(row.uuid, 'correo_cliente', CorreoClienteValue);
  };

  //FECHA DE NACIMIENTO
  const handleFechaNacimientoChange = (e) => {
    setFechaNacimientoValue(e.value)
  };









  
  // TEXT AREAS
  const handleComentariosChange = (e) => {
    setComentariosValue(e.target.value);
  };

  const handleComentariosBlur = () => {
    setEditingComentarios(false);
    handleChangeRow(row.uuid, 'comentarios', comentariosValue);
  };




  // CALENDARS
  const handlePrimeraFechaOnBlur = (e) => {
    handleChangeRow(row.uuid, 'fecha', fechaToString(PrimeraFechaValue), setEditingPrimeraFecha);
  };
  const handleFechaNacimientoOnBlur = (e) => {
    handleChangeRow(row.uuid, 'fecha_nacimiento', fechaToString(FechaNacimientoValue), setEditingFechaNacimiento);
  };




  // SELECTS
  const handleChangeSelect = (e, state) => {

    let value = e.target.value;


    if (state == 'edad_cliente') {
      setEdadCliente(value)

    }
    else if (state == 'producto') {
      setProducto(value)

    }
    else if (state == 'vincular_con') {
      setVincularCon(value)

    }
    else if (state == 'porcentaje_de_cierre') {
      setPorcentajeCierre(value)

    }

    handleChangeRow(row.uuid, state, value)

  }







  //FUNCIONES DE FORMATEO
  function stringToFecha(fechaString) {
    try {
      if (fechaString) {
        const [dia, mes, año] = fechaString.split('/').map(part => parseInt(part, 10));
        return new Date(año, mes - 1, dia);
      }
    } catch {
      return ''
    }
  }
  function fechaToString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }









  useEffect(() => {
    if (initRender == 2) {
      // handleChangeRow(row, estatusDelRegistro)
    } else {
      setInitRender(2)
    }
  }, [])



  return (
    <tr>


      {/* FECHA */}
      <td onClick={() => setEditingPrimeraFecha(true)}>
        {
          editingPrimeraFecha ?
            <InputCalendar
              value={PrimeraFechaValue}
              onChange={handlePrimeraFechaChange}
              onBlur={handlePrimeraFechaOnBlur}
            />

            :
            PrimeraFechaValue ? fechaToString(PrimeraFechaValue) : 'Sin datos'
        }
      </td>



      {/* NOMBRE */}
      <td onClick={() => setEditingNombreCliente(true)}>
        {editingNombreCliente ? (
          <input
            type="text"
            value={NombreClienteValue}
            onChange={handleNombreClienteChange}
            onBlur={handleNombreClienteBlur}
            autoFocus
          />
        ) : (
          NombreClienteValue ? NombreClienteValue : 'Sin datos'
        )}
      </td>


      {/* TELEFONO */}
      <td onClick={() => setEditingTelefono(true)}>
        {editingTelefono ? (
          <input
            type="number"
            pattern="[+]?[0-9]*"
            value={TelefonoValue}
            onChange={handleTelefonoChange}
            onBlur={handleTelefonoBlur}
            autoFocus
          />
        ) : (
          TelefonoValue ? TelefonoValue : 'Sin datos'
        )}
      </td>
      
      {/* CORREO */}
      <td onClick={() => setEditingCorreoCliente(true)}>
        {editingCorreoCliente ? (
          <input
            type="text"
            value={CorreoClienteValue}
            onChange={handleCorreoClienteChange}
            onBlur={handleCorreoClienteBlur}
            autoFocus
          />
        ) : (
          CorreoClienteValue ? CorreoClienteValue : 'Sin datos'
        )}
      </td>


      {/* EDAD */}
      <td>
        <select value={EdadCliente} onChange={e => handleChangeSelect(e, 'edad_cliente')}>
          {EdadClienteOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      {/* FECHA DE NACIMIENTO */}
      <td onClick={() => setEditingFechaNacimiento(true)}>
        {
          editingFechaNacimiento ?
            <InputCalendar
              value={FechaNacimientoValue}
              onChange={handleFechaNacimientoChange}
              onBlur={handleFechaNacimientoOnBlur}
            />

            :
            FechaNacimientoValue ? fechaToString(FechaNacimientoValue) : 'Sin datos'
        }
      </td>


      {/* PRODUCTOS */}
      <td>
        <select value={producto} onChange={e => handleChangeSelect(e, 'producto')}>
          {ProductOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>



      {/* VINCULAR CON  */}
      <td>
        <select value={vincularCon} onChange={e => handleChangeSelect(e, 'vincular_con')}>
          {
            producto ? <React.Fragment>               
              <option key={0} value={""}>Sin datos</option>
                
              {
                VincularConOptions[producto].map(option => (
                  <option key={option.key} value={option.name}>{option.name}</option>
                ))
              }
            </React.Fragment>

            : 
            
              <option key={0} value={""}>Sin datos</option>
          }
        </select>
      </td>




      {/* PORCENTAJE DE CIERRE */}
      <td>
        <select value={porcentajeCierre} onChange={e => handleChangeSelect(e, 'porcentaje_de_cierre')}>
          <option value="">Sin Datos</option>
          {PorcentageOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>



      {/* COMENTARIOS */}
      <td onClick={() => setEditingComentarios(true)}>
        {editingComentarios ? (
          <textarea
            type="text"
            rows={4}
            cols={100}
            value={comentariosValue}
            onChange={handleComentariosChange}
            onBlur={handleComentariosBlur}
            autoFocus
            style={{ resize: "none" }}
          />
        ) : (
          comentariosValue ? comentariosValue : 'Sin comentarios'
        )}
      </td>

    </tr>
  )
}